// 
// Accordion 
// 

@import "../../node_modules/bootstrap/scss/accordion";

.accordion {
  .accordion-item {
    margin-bottom: 0;
    border: 1px solid $blue-secondary;

    // // TODO might need to add other utility classes here, or do some SASS wizardry 
    // &.mb-3 {
    //   border-bottom: 1px solid $blue-secondary;
    // }

    .accordion-header {
      padding: 0;
      border-radius: 0;

      a, button {
        padding: 1rem;
        display: block;
        font-weight: bold;
        transition: 0.2s;
        text-decoration: none;
        background: $light;
        color: $black;
        // border-bottom: 1px solid $blue-secondary;

        &[aria-expanded="true"] {
          // background: $blue-fourth
          border-bottom: 1px solid $light;
        }
      }
    }
  }
}
