//
// Buttons
//

@import "../../node_modules/bootstrap/scss/buttons";

.btn {
  font-weight: bold;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: $pink;
  border-color: $pink;

  &:hover {
    background: $pink;
  }
}

.btn-group {
  .btn {
    margin-right: 10px;
  }
  .btn:last-child {
    margin-right: 0;
  }
}

.btn-rounded-corners {
  border-radius: 20px;
  padding-right: 20px;
  padding-left: 20px;
}





// // 
// //  List filter buttons 
// // 

// // .filter-buttons {
  
// //   // hide checkbox 
// //   input[type='checkbox'],
// //   input[type='radio'] {
// //     position: absolute;
// //     left: -9999px;
// //   }

// //   // set selected state of buttons  
// //   input[type='checkbox']:checked,
// //   input[type='radio']:checked {
// //     + .btn {
// //       color: $white;
// //     }
// //   }

// //   @each $color, $value in $theme-colors {
// //     input[data-color='#{$color}']:checked + .btn {
// //       background-color: $value;
// //     }
// //   }

// //   // disable hover state 
// //   .btn:hover {
// //     background-color: white;
// //   }

// //   @each $color, $value in $theme-colors {
// //     .btn-outline-#{$color} {
// //       color: $value;
// //       background-color: $white;
// //     }
// //   }
// // }
