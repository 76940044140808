// // variables 
// $enable-rounded: true !default;

// mobile
@media (max-width: 767px) {
  .circular-slider {
    background-size: 0 0;

    .wrapper {
      .controls {
        display: none;
      }
      .slides-holder {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .slider {
    width: 100%;
    height: 500px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* padding: 20px; */
  }
  
  .circular-slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: $blue-third;
  
    -webkit-transition: background-image 0.2s ease-in-out;
    // transition: background-image 0.2s ease-in-out;
    background-size: 100% 100%;
    transition: 0.3s ease 0s;
  }
  .circular-slider .wrapper {
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px 20px 0px 20px;
    overflow: hidden;
  }
  .circular-slider .wrapper .controls__left,
  .circular-slider .wrapper .controls__right,
  .circular-slider .wrapper .controls__autoplay {
    position: absolute;
    z-index: 101;
    -webkit-transition: 0.6s all;
    -o-transition: 0.6s all;
    transition: 0.6s all;
  }
  .circular-slider .wrapper .controls__left:hover .icon-wrapper,
  .circular-slider .wrapper .controls__right:hover .icon-wrapper,
  .circular-slider .wrapper .controls__autoplay:hover .icon-wrapper {
    font-size: 1.7em;
    opacity: 1;
  }
  .circular-slider .wrapper .controls__left .icon-wrapper,
  .circular-slider .wrapper .controls__right .icon-wrapper,
  .circular-slider .wrapper .controls__autoplay .icon-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $white;
    font-size: 1.5em;
    opacity: 0.7;
  }
  .circular-slider .wrapper .controls__left,
  .circular-slider .wrapper .controls__right {
    top: 50%;
  }
  .circular-slider .wrapper .controls__left {
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .circular-slider .wrapper .controls__left:hover {
    left: 0;
  }
  .circular-slider .wrapper .controls__right {
    right: 0;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
  }
  .circular-slider .wrapper .controls__autoplay {
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }
  .circular-slider .wrapper .controls__autoplay_running .pause {
    display: block;
  }
  .circular-slider .wrapper .controls__autoplay_running .run {
    display: none;
  }
  .circular-slider .wrapper .controls__autoplay_paused .pause {
    display: none;
  }
  .circular-slider .wrapper .controls__autoplay_paused .run {
    display: block;
  }
  .circular-slider .wrapper .slides-holder {
    border-radius: 50%;
    border: 5px solid $white;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 100;
    // pointer-events: none;
  }
  .circular-slider .wrapper .slides-holder__item {
    border-radius: 50%;
    border: 5px solid $white;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    background-color: $blue-third;
    -webkit-transition: 0.3s linear all;
    -o-transition: 0.3s linear all;
    transition: 0.3s linear all;
    // -webkit-filter: brightness(70%);
    //         filter: brightness(70%);
  }
  .circular-slider .wrapper .slides-holder__item img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .circular-slider .wrapper .slides-holder__item_active {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
  }
  .circular-slider .wrapper .descriptions {
    position: absolute;
    bottom: 0%;
    color: $white;
    z-index: 0;
  }
  .circular-slider .wrapper .descriptions .description_heading {
    color: $white !important;
  }
  .circular-slider .wrapper .descriptions .description {
    overflow: auto;
  }
  .circular-slider .wrapper .descriptions__item {
    width: 100%;
    height: 0%;
    opacity: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: opacity 0s 0s linear;
    -o-transition: opacity 0s 0s linear;
    transition: opacity 0s 0s linear;
  }
  .circular-slider .wrapper .descriptions__item_visible {
    height: 100%;
    opacity: 1;
    -webkit-transition: opacity 0.6s 0s linear;
    -o-transition: opacity 0.6s 0s linear;
    transition: opacity 0.6s 0s linear;
  }
  .circular-slider .wrapper .descriptions__item h1,
  .circular-slider .wrapper .descriptions__item .description {
    /* font-family: Helvetica, sans-serif; */
    /* color: white; */
    text-align: center;
  }
  .circular-slider .wrapper .descriptions__item h1 {
    font-size: 200%;
    padding-top: 5px;
  }
  .circular-slider .wrapper .descriptions__item .description {
    font-size: 100%;
    margin-top: 5px;
    padding: 0% 10%;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow-y: hidden;
  }  
}