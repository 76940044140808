// 
// Navbar 
// 


@import "../../node_modules/bootstrap/scss/navbar";

.navbar {
//     &.bg-primary {
//         .navbar-nav {
//             .nav-link {
//                 color: $white;
//             }
//         }
//     }
//     &.bg-white {
//         .navbar-nav {
//             .nav-link {
//                 color: $blue-secondary;
//                 font-weight: bold;
//             }
//         }
//     }

    .dropdown-submenu {
        position: relative;
    }
    
    .dropdown-submenu a::after {
        transform: rotate(-90deg);
        position: absolute;
        right: 6px;
        top: .8em;
    }
    
    .dropdown-submenu .dropdown-menu {
        top: 0;
        left: 100%;
        margin-left: .1rem;
        margin-right: .1rem;
    }
}

// @include media-breakpoint-up(lg) {
//     .navbar-nav {
//         margin-top: 40px;
//         margin-left: 30px;
//     }
// }



.navbar-nav-active-border {
    .nav-item {
        .nav-link {
            padding: 4px 0;
            margin: 0 20px;
            padding: 10px 0;

            &:first-child {
                margin-left: 0;
            }
            &:hover,
            &:focus {
                border-bottom: 4px solid $gray-200;
            }
            &.active {
                border-bottom: 4px solid $orange;
            }
        }
    }
}

.navbar.sticky-top {
    .nav-link {
        font-size: 0.9rem;
        font-weight: bold;
    }
}
