.jumbotron {
    margin-bottom: 2rem;
    background-color: #e9ecef;
    background-size: cover;
}

.homepage-jumbotron {
  background-size: cover;
  min-height: 400px;
  background-position: center;

  .slide-in-effect {
    animation: fadeIn 0.5s;
    animation-timing-function: ease-out;
  }
}

@keyframes fadeIn {
  from {
    margin-left: 100px;
    opacity: 0;
  }

  to {
    margin-left: 0px;
    opacity: 1;
  }
}

.homepage-jumbotron-block {
  margin-right: 20px;
  background: none;
  background-color: rgba(0, 0, 0, 0.2);
}

.homepage-jumbotron-block:first-child {
  margin-left: 0;
}

.homepage-jumbotron-block.active {
  border-top: 7px solid #8ec89a !important;
}

.homepage-jumbotron-pause-button {
  position: absolute;
  left: 50%;
  top: 220px;
  z-index: z("homepage-pause-button"); // 9999
  cursor: pointer;
  font-size: 5rem;
  opacity: 0.7;
  color: white;
  transform: translate(-50%, -50%);
  transition: .2s;
}

.homepage-jumbotron-pause-button:hover {
  opacity: 0.9;
  color: white;
}