//
// Card
//

@import "../../node_modules/bootstrap/scss/card";

.card {
  background: $gray-100;
  border: 0;
  border-radius: 0;
  background-repeat: no-repeat;
  background-position: right bottom;
  color: #00294B;
  // box-shadow: 3px 3px 3px $gray-400;

  .card-body {
    padding: 1rem 1.25rem;
    font-size: 0.9rem;
    line-height: 1.75;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      font-size: 1.17rem;
      line-height: 1.75rem;
      margin-top: 0px !important;
    }
  }

  &.bg-primary,
  &.bg-blue-secondary-900,
  &.bg-blue-secondary-800 {

    color: $white;

    // TODO use a function for this, and add to variables $shadow-sm value too so spread etc defined once 
    &.shadow-sm {
      box-shadow: 3px 3px 3px $black !important;
    }

    // .card-body {
    //   h1, h2, h3, h4, h5, h6 {
    //     color: $white !important;
    //   }
    // }

    .btn.btn-primary {
      background-color: transparent;
      border: 2px solid $white;

      &:hover,
      &:focus,
      &:active {
        background-color: $white;
        color: $blue-secondary-900;
      }
    }
  }
}

.card-icon-group {
  position: absolute;
  top: 0;
  margin-top: calc(-1rem - 8px);
  margin-left: $card-spacer-x;

  .card-icon {
    background: $white;
    border-radius: 50%;
    padding: 10px;
  }
}

.card-icon-group-purple {
  .card-icon {
    border: 3px solid $purple;
    color: $purple;
  }
}

.card-icon-group-green {
  .card-icon {
    border: 3px solid $green;
    color: $green;
  }
}

.card-icon-group-orange {
  .card-icon {
    border: 3px solid $orange;
    color: $orange;
  }
}

.card-icon-group-blue {
  .card-icon {
    border: 3px solid $blue-third;
    color: $blue-third;
  }
}

.card-with-icon {
  padding-top: 1rem;
}

.card-header {
  background: none;
  padding-top: 1rem;
  padding-bottom: 0;
  border: 0;
}

.card-footer {
  background: none;
  padding-top: 0;
  padding-bottom: 1rem;
  border: 0;
}

.card-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

// .card-hover-effect-bg-pink:hover {
//   background-color: $pink !important;
//   color: $white !important;

//   h3, p, i {
//     color: $white !important;
//   }
// }


// 
// Card row (image left)
// 

.card.card-row {
  img {
    height: 300px;
  }
  .card-body {
    height: 300px;
    overflow: auto;
  }
}

@media only screen and (min-width: 768px) {
  .card.card-row {
    flex-direction: row;
    align-items: top;
  
    img {
      // width: 50%;
      max-width: 300px;
      border-top-right-radius: 0;
      border-bottom-left-radius: calc(0.25rem - 1px);
    }
  }
}

// 
// Section specific cards 
// 

@mixin card-icon-colors($color) {
  border: 3px solid $color;
  color: $color;
}

@each $color, $value in $theme-colors {
  .card-border-top-#{$color} {
    @extend .border-top-#{$color};

    .card-icon {
      @include card-icon-colors($value);
    }
  }
}



// .card .card-body-reveal-overlay {
//   margin-top: auto;
//   overflow: auto;
// }

// .card:hover,
// .card:focus,
// .card:active {

//   .card-body-reveal-overlay {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//   }
  
// }

// .card .card-body .card-body-reveal-content[aria-hidden='true'] {
//   overflow-y: hidden;
//   display: none;
// }

// @mixin show-card-body-reveal-content() {
//   max-height: 100%;
//   display: block !important;
// }

// .card:hover,
// .card:focus,
// .card:active {

//   .card-body .card-body-reveal-content {
//     @include show-card-body-reveal-content();
//   }

// }

// @include media-breakpoint-down(sm) {
//   .card .card-body .card-body-reveal-content {
//     @include show-card-body-reveal-content();
//   }
// }
